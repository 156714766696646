<template>
  <div class="letter_home_page">
    <myTable title="站内信" :columns="columns" :list="list">
      <template slot="top_block">
        <div class="code_block">
          <!-- <el-button @click="addStu" class="table_top_btn_default">完善学校信息 +</el-button> -->
        </div>
      </template>
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div class="table_btn" @click="handleView(item.data.row)">
            详情
          </div>
          <div class="table_btn" @click="handleDel(item.data.row)">删除</div>
        </div>
      </template>
    </myTable>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
  import myTable from '@/components/admin/table.vue'
  import comPagination from '@/components/admin/pagination.vue'
  export default {
    name: 'letter',
    components: {
      comPagination,
      myTable
    },
    data() {
      return {
        //班级列表
        columns: [
        {
          prop: 'messageId',
          label: '编号',
        },
        {
          prop: 'messageTitle',
          label: '消息标题',
        },
        {
          prop: 'updatetime',
          label: '发送时间',
        },
        {
          prop: 'phone',
          label: '状态',
        }],
        list: [],
        //分页
        params: {
          page: 0,
          size: 10,
          total: 0
        }
      }
    },
    created() {},
    mounted() {
      this.init()
    },
    methods: {
      //初始化
      init() {
        this.getInfo()
      },
      getInfo() {
        let data = {
          userId: this.$store.state.userInfo.schoolId,
        }
        this.api.admin.messageNotice(this.params, data).then((res) => {
          console.log(res)
          this.params.total = res.data.total
          this.list = res.data.list.map(item => {
            let data = { ...item, ...item.messageNotice }
            data.createtime = this.common.dateFormat(data.createtime * 1000)
            data.updatetime = this.common.dateFormat(data.updatetime * 1000)
            return data
          })
        })
      },
      handleView(val) {
        console.log(val)
        this.$router.push({
          path: '/admin/letterDetail',
          query: {
            ...val
          }
        })
      },
      handleDel(val) {
        console.log(val)
        this.$confirm('此操作将删除该消息, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            let data = {
              messageId: val.messageId,
              userId: val.userId
            }
            this.api.admin.deleteStatus(data).then(res => {
              if (res.flag) {
                this.$notice({
                  type: 'success',
                  message: '删除成功!'
                }).isShow()
              } else {
                this.$notice({
                  type: 'warn',
                  message: '删除失败!'
                }).isShow()
              }
              this.getInfo()
            })

          })
          .catch(() => {
            this.$notice({
              type: 'warn',
              message: '已取消删除'
            }).isShow()
            this.getInfo()
          })
      },

      //分页 todo 待测试
      changePage(val) {
        this.params.page = val
        this.getInfo()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .letter_home_page {}
</style>
<style lang="scss">